/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "trix"
import "@rails/actiontext"
import "@hotwired/turbo-rails"
import "../controllers/index"
import Rails from "@rails/ujs"

Rails.start()
window.Rails = Rails

console.log('Hello World from Webpacker')

document.addEventListener('turbo:load', async (event) => {
  console.log("turbo:load - initial page load or visit "); 
});

document.addEventListener('turbo:render', async (event) => {
  console.log("turbo:render - renders the page (cache then fresh)");
});

document.addEventListener('turbo:frame-render', async (event) => {
  console.log("turbo:frame-render' - turbo-frame element renders its view");
});

