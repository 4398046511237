import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  connect() {
    console.log("Hello from drag controller")
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      forceFallback: true,
      handle: '.handle'
    })
  }

  end(event) {
    console.log(event)
    let data = new FormData()
    let id = event.item.dataset.id
    data.append("position", event.newIndex + 1)
    Rails.ajax({
      url: this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data: data
    })
  }
}
